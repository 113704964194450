let Autoload = {

    data() {
        return {
            error           : null,
            autoload        : false,
            loading         : false,
            _autoload_timer : null,
        }
    },

    watch : {
        autoload() {
            this._autoload_stop();
            if (this.autoload) {
                this.load();
                this._autoload_timer = setInterval(() => {
                    this.load();
                }, 5000);
            }
        }
    },

    methods : {
        loadInternal : function() {

        },

        load() {
            if (this.loading) {
                return;
            }
            try {
                this.loading = true;
                Promise.resolve(this.loadInternal()).then(() => {
                    this.loading = false;
                    this.error = null;
                }).catch((error) => {
                    this.loading = false;
                    this.error = error.message || error;
                });
            } catch (error) {
                this.loading = false;
                this.error = error.message || error;
            }
        },

        debouncedLoad : function () {
            let DELAY = 500;
            return this.$debounce(this.load, DELAY);
        },

        _autoload_stop : function() {
            if (this._autoload_timer) {
                clearInterval(this._autoload_timer);
                this._autoload_timer = null;
            }
        }
    },

    beforeDestroy : function() {
        this._autoload_stop();
    }
};

export default Autoload;