<template>
    <v-card class="fude-table" v-cloak>

        <fude-search-toolbar
                :color="color"
                :showTitle="showTitle"
                :actions="titleActions"
                :rightActions="rightActions"
                :showSearch="showSearch"
                :showAutoload="showAutoload"
                :clickableTitle="clickableTitle"
                @titleClick="titleClick"
                @load="load"
                ref="toolbar"
        >
            <slot/>
        </fude-search-toolbar>

        <fude-error-alert :error="errorMessage"/>

        <v-data-table
                class="fude-table-data"
                :style="style"
                :headers="headers"
                :items="items"
                :pagination.sync="pagination"
                :total-items="row_count"
                :loading="loading"
                :no-data-text="noData"
                hide-actions
                must-sort
                :class="tableClasses"
        >
            <template slot="headerCell" slot-scope="props">
                <v-btn v-if="props.header.actions && allowAdd()" fab dark color="green" class="action-btn xsmall" style="margin-right: 0" @click.stop="add()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <span v-else>
                  {{ props.header.text }}
                </span>
            </template>
            <template slot="items" slot-scope="props">
                <td v-for="header in headers" v-if="!header.actions" style="cursor: pointer" :style="{ textAlign: header.align}" @click="allowEdit(props.item) && edit(props.item)">
                    <slot :name="header.field" :item="props.item">
                        {{ props.item[header.field] }}
                    </slot>
                </td>
                <td>
                    <div v-if="hasActions" class="action-btns">
                        <template v-if="settings.actions.rowActions && settings.actions.rowActions.length">
                            <v-btn
                                    v-for="(action, index) in settings.actions.rowActions"
                                    :key="index"
                                    :class="{ 'hide-brn' : action.allow && !action.allow(props.item) }"
                                    fab
                                    dark
                                    small
                                    :color="action.color"
                                    class="xsmall"
                                    @click="action.handle(props.item)"
                            >
                                <v-icon>{{ action.icon }}</v-icon>
                            </v-btn>
                        </template>
                        <v-btn v-if="settings.actions.edit" :class="{ 'hide-brn' : !allowEdit(props.item) }" fab dark small color="amber" class="xsmall" @click="edit(props.item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="settings.actions.remove" :class="{ 'hide-brn' : !allowRemove(props.item) }" fab dark small color="red" class="xsmall" @click="remove(props.item)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </td>
            </template>

            <slot v-if="$slots.footerRows" name="footerRows" slot="footer"/>
        </v-data-table>

        <div v-if="enablePagination && pageCount > 1" class="text-xs-center pt-2" :class="tableClasses">
            <v-pagination
                    v-model="pagination.page"
                    :length="pageCount"
                    :total-visible="7"
                    circle
                    :disabled="loading"
            ></v-pagination>
        </div>

        <slot name="tableFooter"/>
    </v-card>
</template>

<script>
    import Autoload from '../../mixins/autoload';
    import Errorable from '../../mixins/errorable';
    import { orderBy } from 'lodash';

    const NEW_ITEM_ID = 'new';

    export default {
        name : 'fude-table',

        mixins : [
            Autoload,
            Errorable
        ],

        props : {
            settings  : {},
            collapsed : {}
        },

        data() {
            return {
                headers : [],
                items   : [],

                pagination : {
                    rowsPerPage : 18
                },
                row_count : 0,

                searchText : '',

                dialogOpened : false
            }
        },

        watch : {
            pagination: {
                handler () {
                    this.$refs.toolbar.load();
                },
                deep: true
            },
            'settings.headers' : {
                handler() {
                    this.headers = this.getHeaders();
                    this.validatePagination(this.headers);
                    this.$nextTick(() => this.onInit());

                },
                immediate: true
            },
            '$localization.current' : {
                handler() {
                    this.headers = this.getHeaders();
                    this.validatePagination(this.headers);
                },
                immediate: true
            },
            'settings.rowsPerPage' : {
                handler() {
                    this.pagination.rowsPerPage = (this.settings && this.settings.rowsPerPage) || 20;
                },
                immediate: true
            },
            '$route.query' : {
                handler() {
                    if (!this.disableQueryWatcher)
                        this.openByQuery(true);
                },
                deep : true
            }
        },

        computed : {
            color() {
                return this.settings.color;
            },
            showTitle() {
                return this.settings.title;
            },
            showSearch() {
                return this.settings.search && !this.collapsed;
            },
            showAutoload() {
                return this.settings.autoload;
            },

            pageCount() {
                return this.pagination.rowsPerPage ? Math.ceil(this.row_count / this.pagination.rowsPerPage) : 0
            },

            enablePagination() {
                if (this.settings && this.settings.pagination === false) {
                    return false;
                }
                return true;
            },

            style() {
                if (this.settings && this.settings.maxWidth) {
                    return {
                        maxWidth : this.settings.maxWidth
                    }
                }
            },

            hasActions() {
                return this.settings && !!this.settings.actions;
            },

            baseUrl() {
                return (typeof this.settings.rest === 'function')
                    ? this.settings.rest()
                    : this.settings.rest;
            },

            noData() {
                return this.$t(this.settings.noData || 'fude.table.no-data');
            },

            enableFilter() {
                return this.$slots;
            },

            clickableTitle() {
                return !!this.settings.titleClick;
            },

            titleActions() {
                return this.settings && this.settings.titleActions;
            },

            rightActions() {
                return this.settings && this.settings.rightActions;
            },

            tableClasses() {
                return this.collapsed ? 'fude-table__collapsed' : ''
            }
        },

        methods : {
            getHeaders() {
                let result = [];
                if (this.settings && this.settings.headers) {
                    let headers = this.settings.headers;
                    if (Array.isArray(headers)) {
                        headers.forEach((settings) => {
                            result.push({
                                field    : settings.field,
                                text     : settings.text.text ? settings.text.text : this.$t(settings.text),
                                value    : settings.value,
                                width    : settings.width,
                                class    : settings.class,
                                sortable : settings.sortable,
                                default  : settings.default
                            });
                        });
                    } else {
                        Object.keys(headers).forEach((field) => {
                            let settings = headers[field];
                            if (typeof settings === 'string') {
                                result.push({
                                    field,
                                    text : this.$t(settings),
                                });
                            } else {
                                result.push({
                                    field,
                                    text     : settings.text.text ? settings.text.text : this.$t(settings.text),
                                    value    : settings.value,
                                    width    : settings.width,
                                    class    : settings.class,
                                    sortable : settings.sortable,
                                    default  : settings.default
                                });
                            }
                        });
                    }
                    result.forEach((header) => {
                        header.align = 'left';
                        if (!header.value) {
                            header.value = header.field;
                        }
                        if (header.sortable == null) {
                            header.sortable = true;
                        }
                        if (header.default) {
                            this.pagination.sortBy = header.value;
                            this.pagination.descending = header.default === 'desc';
                        }
                    });
                    result.push({
                        actions  : true,
                        align    : 'right',
                        sortable : false,
                        value    : '_actions_'
                    });
                }
                return result;
            },

            allowAction(item, action) {
                return this.settings.actions &&
                       this.settings.actions[action] && (
                            !this.settings.actions[action].allow ||
                            this.settings.actions[action].allow(item)
                    );
            },

            allowAdd() {
                return this.allowAction(this.items, 'add');
            },

            allowEdit(item) {
                return this.allowAction(item, 'edit');
            },

            allowRemove(item) {
                return this.allowAction(item, 'remove');
            },

            async load({ searchText, itemsCount }) {
                if (!this.settings) {
                    return;
                }
                try {
                    this.$setError();

                    if (this.settings.items) {

                        let items = this.settings.items || [];
                        if (this.settings.onLoad) {
                            items = this.settings.onLoad(items) || items;
                        }

                        let filtered = items;
                        if (this.settings.search) {
                            searchText = (searchText || '').trim();
                            if (searchText) {
                                searchText = searchText.toLowerCase();
                                filtered = items.filter((item) => {
                                    let find = false;
                                    for (let i = 0; i < this.headers.length; i++) {
                                        let h = this.headers[i];
                                        let v = (item[h.field] || '').toString().toLowerCase();
                                        if (v.indexOf(searchText) >= 0) {
                                            find = true;
                                            break;
                                        }
                                    }
                                    return find;
                                })
                            }
                        }

                        let s = this.pagination.sortBy;
                        let d = this.pagination.descending;
                        if (s) {
                            filtered = orderBy(filtered, this.settings.headers[s].sortHandler || s, d ? 'desc' : 'asc');
                        }

                        let paged = filtered;
                        let p = this.pagination.page;
                        let c = this.settings.rowsPerPage || this.pagination.rowsPerPage;
                        paged = filtered.slice((p - 1) * c, p * c);

                        this.items = paged;
                        itemsCount(this.row_count = filtered.length);

                        this.openByQuery();
                    } else {
                        let request = this.baseUrl;
                        if (this.settings.search) {
                            let params = {
                                page: this.pagination.page - 1,
                                count: this.pagination.rowsPerPage,
                                sort: this.pagination.sortBy,
                                desc: this.pagination.descending,
                                text: searchText
                            };
                            if (typeof this.settings.search === 'function') {
                                params = this.settings.search(params);
                            }
                            let query = encodeURIComponent(JSON.stringify(params));
                            request += `/search/${query}`;
                        }

                        let res = await this.$http.get(request);
                        let items = res.data;
                        if (this.settings.onLoad) {
                            items = this.settings.onLoad(items) || items;
                        }
                        this.items = items;

                        if (this.settings.search) {
                            itemsCount(this.row_count = this.items.length ? parseInt(this.items[0].row_count) : 0);
                        } else {
                            itemsCount(this.row_count = this.items.length);
                        }

                        this.openByQuery();

                        if (this.settings.openFirst) {
                            if (this.items && this.items.length > 0) {
                                this.edit(this.items[0]);
                            }
                        }
                    }
                } catch (error) {
                    this.$setError(error);
                }
            },

            validatePagination(headers) {
                if (!headers.filter((header) => header.sortable && header.value === this.pagination.sortBy).length) {
                    let sorts = headers.filter((header) => header.sortable);
                    if (sorts.length) {
                        this.pagination.sortBy = sorts[0].value;
                        this.pagination.descending = false;
                    } else {
                        this.pagination.sortBy = '';
                    }
                }
            },

            getModel(item) {
                if (this.settings.actions.model) {
                    return Promise.resolve(this.settings.actions.model(item));
                }
                return Promise.resolve(item);
            },

            async getDto(item) {
                if (this.settings.actions.dto) {
                    item = await Promise.resolve(this.settings.actions.dto(item));
                }
                if (this.settings.formdata) {
                    let itemData = new FormData();
                    let __fields__ = {};
                    Object.keys(item).forEach((key) => {
                        let value = item[key];
                        if (value && typeof value === 'object') {
                            if (value.file instanceof File) {
                                itemData.append(key, value.file, value.file.name);
                            }
                        } else {
                            __fields__[key] = value;
                        }
                    });
                    itemData.append('__fields__', JSON.stringify(__fields__));
                    item = itemData;
                }
                return item;
            },

            async openByQuery(reload) {
                let field = this.settings.linkField;
                if (field && (!this.dialogOpened || reload)) {
                    let opened = false;
                    try {
                        opened = await this.openById(this.$route.query[field], field);
                    } catch (error) {
                        this.$dialog.$info(this.$getErrorMessage(error));
                    }
                    if (!opened) {
                        this.$router.push(this.$route.path);
                    }
                }
            },

            async openById(id, field) {
                if (!id || !this.items) {
                    return false;
                }

                this.$dialog.$hide(this._dialogId);

                if (id === NEW_ITEM_ID) {
                    this.add();
                    return true;
                }
                field = field || 'id';
                let item = this.items.find((i) => i[field] === id);
                if (!item && !this.settings.items) {
                    let res = await this.$http.get(`${this.baseUrl}/${id}`);
                    item = res.data;
                }
                if (item) {
                    this.edit(item);
                    return true;
                }
                return false;
            },

            getActions(actions, model) {
                return actions.map(({ result, name, color, left, top, confirm, handle, reload, close }) => ({
                    result,
                    name,
                    color,
                    left,
                    top,
                    confirm,
                    close,
                    handle : async () => {
                        let dto = await this.getDto(model);
                        if (handle) {
                            await handle(dto);
                        }
                        if (reload !== false) {
                            this.$refs.toolbar.load();
                        }
                    }
                }))
            },

            async add() {
                this.$setError();
                if (this.settings.actions.redirect) {
                    try {
                        let url = await this.settings.actions.redirect();
                        return this.$navigate(url);
                    } catch (error) {
                        this.$setError(error);
                    }
                }
                this.getModel({}).then((model) => {
                    let actions = [];
                    if (this.settings.actions.custom) {
                        actions.push(...this.getActions(this.settings.actions.custom, model));
                    }
                    if (this.settings.actions.add.actions) {
                        actions.push(...this.getActions(this.settings.actions.add.actions(model), model));
                    } else {
                        actions.push({
                            result : 'OK',
                            name   : this.$t('fude.dialog.actions.ok'),
                            handle : async () => {
                                let reload;
                                let dto = await this.getDto(model);
                                if (this.settings.items) {
                                    this.settings.items.push(dto);
                                } else {
                                    let res = await this.$http.post(this.baseUrl, dto);
                                    reload = await this.resolve(this.settings.actions.add.done, res.data, dto);
                                }
                                if (reload !== false) {
                                    this.$refs.toolbar.load();
                                }
                            }
                        },{
                            result : 'CANCEL',
                            name   : this.$t('fude.dialog.actions.cancel'),
                        });
                    }

                    this._dialogId = this.$dialog.$show({
                        title         : this.settings.actions.add.title(),
                        actionsTop    : this.settings.actions.actionsTop,
                        fullScreen    : this.settings.actions.fullScreen,
                        autoWidth     : this.settings.actions.autoWidth,
                        component     : this.settings.actions.dialog,
                        wideContainer : this.settings.actions.wideContainer,
                        model,
                        actions,
                        onShow : () => {
                            this.dialogOpened = true;
                            if (this.settings.linkField) {
                                this.disableQueryWatcher = true;
                                this.$router.push(this.$route.path + `?${this.settings.linkField}=${NEW_ITEM_ID}`);
                                this.$nextTick(() => {
                                    this.disableQueryWatcher = false;
                                });
                            }
                        },
                        onHide : () => {
                            this.dialogOpened = false;
                            if (this.settings.linkField) {
                                this.disableQueryWatcher = true;
                                this.$router.push(this.$route.path);
                                this.$nextTick(() => {
                                    this.disableQueryWatcher = false;
                                });
                            }
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                });
            },

            async edit(item) {
                this.$setError();
                if (this.settings.actions.redirect) {
                    try {
                        let url = await this.settings.actions.redirect(item);
                        return this.$navigate(url);
                    } catch (error) {
                        this.$setError(error);
                    }
                }
                this.getModel(item).then((model) => {
                    let actions = [];
                    if (this.settings.actions.custom) {
                        actions.push(...this.getActions(this.settings.actions.custom, model));
                    }
                    if (this.settings.actions.edit.simple) {
                        actions.push({
                            result : 'CANCEL',
                            name   : this.$t('fude.dialog.actions.ok'),
                            handle : () => this.$refs.toolbar.load()
                        })
                    } else if (this.settings.actions.edit.actions) {
                        actions.push(...this.getActions(this.settings.actions.edit.actions(model), model));
                    } else {
                        actions.push({
                            result : 'OK',
                            name   : this.$t('fude.dialog.actions.ok'),
                            handle : async () => {
                                let res;
                                let dto = await this.getDto(model);
                                if (this.settings.items) {
                                    let index = this.settings.items.indexOf(item);
                                    this.settings.items[index] = dto;
                                    res = dto;
                                } else {
                                    res = await this.$http.put(`${this.baseUrl}/${item.id}`, dto);
                                }
                                await this.resolve(this.settings.actions.edit.done, res.data, dto);
                                this.$refs.toolbar.load();
                            }
                        },{
                            result : 'CANCEL',
                            name   : this.$t('fude.dialog.actions.cancel'),
                        });
                    }
                    this._dialogId = this.$dialog.$show({
                        title         : this.settings.actions.edit.title(),
                        actionsTop    : this.settings.actions.actionsTop,
                        fullScreen    : this.settings.actions.fullScreen,
                        autoWidth     : this.settings.actions.autoWidth,
                        component     : this.settings.actions.dialog,
                        wideContainer : this.settings.actions.wideContainer,
                        model,
                        actions,
                        onShow : () => {
                            this.dialogOpened = true;
                            if (this.settings.linkField) {
                                this.disableQueryWatcher = true;
                                this.$router.push(this.$route.path + `?${this.settings.linkField}=${item[this.settings.linkField]}`, () => {
                                    this.$nextTick(() => this.disableQueryWatcher = false);
                                }, () => {
                                    this.$nextTick(() => this.disableQueryWatcher = false);
                                });
                            }
                        },
                        onHide : () => {
                            this.dialogOpened = false;
                            if (this.settings.linkField) {
                                this.disableQueryWatcher = true;
                                this.$router.push(this.$route.path, () => {
                                    this.$nextTick(() => this.disableQueryWatcher = false);
                                }, () => {
                                    this.$nextTick(() => this.disableQueryWatcher = false);
                                });
                            }
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                });
            },

            remove(item) {
                this.$setError();
                this.$dialog.$confirm(this.settings.actions.remove.title(item)).then(() => {
                    if (this.settings.rest) {
                        this.$http.delete(`${this.baseUrl}/${item.id}`).then((res) => {
                            if (this.settings.items) {
                                let index = this.settings.items.indexOf(item);
                                this.settings.items.splice(index, 1);
                            }
                            this.resolve(this.settings.actions.remove.done, res.data, item).then(() => this.$refs.toolbar.load());
                        }).catch((error) => {
                            this.$setError(error);
                        });
                    } else if (this.settings.items) {
                        let index = this.settings.items.indexOf(item);
                        this.settings.items.splice(index, 1);
                        this.$refs.toolbar.load();
                    }
                }).catch(() => {
                });
            },

            resolve(target, params, dto) {
                return Promise.resolve(typeof target === 'function'
                    ? target(params, dto)
                    : target);
            },

            onInit() {
                if (this.settings && this.settings.onInit) {
                    this.settings.onInit(this);
                }
            },

            reload() {
                this.$refs.toolbar.load();
            },

            titleClick() {
                this.settings.titleClick && this.settings.titleClick();
            }
        },

        eventBus : {
            CURRENT_LOCALE_CHANGED() {
                this.reload();
            }
        },

        beforeDestroy() {
            this.$dialog.$hide(this._dialogId);
        }
    }
</script>

<style>
    .fude-table {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .fude-table__collapsed {
        display: none!important;
    }

    .fude-table table.v-table thead td:not(:nth-child(1)),
    .fude-table table.v-table tbody td:not(:nth-child(1)),
    .fude-table table.v-table tfoot td:not(:nth-child(1)),
    .fude-table table.v-table thead th:not(:nth-child(1)),
    .fude-table table.v-table tbody th:not(:nth-child(1)),
    .fude-table table.v-table tfoot th:not(:nth-child(1)),
    .fude-table table.v-table thead td:first-child,
    .fude-table table.v-table tbody td:first-child,
    .fude-table table.v-table tfoot td:first-child,
    .fude-table table.v-table thead th:first-child,
    .fude-table table.v-table tbody th:first-child,
    .fude-table table.v-table tfoot th:first-child{
        padding: 0 8px;
    }
    .fude-table table.v-table thead td:first-child,
    .fude-table table.v-table tbody td:first-child,
    .fude-table table.v-table tfoot td:first-child,
    .fude-table table.v-table thead th:first-child,
    .fude-table table.v-table tbody th:first-child,
    .fude-table table.v-table tfoot th:first-child{
        padding-left: 24px;
    }
    .fude-table table.v-table thead td:last-child,
    .fude-table table.v-table tbody td:last-child,
    .fude-table table.v-table tfoot td:last-child,
    .fude-table table.v-table thead th:last-child,
    .fude-table table.v-table tbody th:last-child,
    .fude-table table.v-table tfoot th:last-child {
        padding-right: 24px;
    }

    .fude-table table.v-table thead td,
    .fude-table table.v-table thead th,
    .fude-table table.v-table tfoot th {
        font-size: 15px;
        font-weight: 500;
    }

    .fude-table .fude-table-data {
        display: flex;
        align-self: center;
        width: 100%;
    }

    .fude-table .action-btn {
        margin: 2px;
    }

    .fude-table table.v-table thead tr,
    .fude-table table.v-table tbody tr,
    .fude-table table.v-table tfoot tr,
    .fude-table table.v-table tbody td,
    .fude-table table.v-table tbody th,
    .fude-table table.v-table tfoot th,
    .fude-table table.v-table tfoot td {
        height: 48px;
    }

    .fude-table.dense table.v-table thead tr,
    .fude-table.dense table.v-table tbody tr,
    .fude-table.dense table.v-table tfoot tr,
    .fude-table.dense table.v-table tbody td,
    .fude-table.dense table.v-table tbody th,
    .fude-table.dense table.v-table tfoot td,
    .fude-table.dense table.v-table tfoot th {
        height: 36px;
    }
    .fude-table.dense .v-toolbar__content {
        height: 38px!important;
    }
    .fude-table.dense .v-toolbar__content .v-toolbar__title {
        font-size: 16px;
    }
    .fude-table.dense .v-btn {
        margin: 4px;
    }

    .fude-table.dense-xs table.v-table thead tr,
    .fude-table.dense-xs table.v-table tbody tr,
    .fude-table.dense-xs table.v-table tfoot tr,
    .fude-table.dense-xs table.v-table tbody td,
    .fude-table.dense-xs table.v-table tbody th,
    .fude-table.dense-xs table.v-table tfoot td,
    .fude-table.dense-xs table.v-table tfoot th {
        height: 32px;
    }
    .fude-table.dense-xs .v-toolbar__content {
        height: 32px!important;
    }
    .fude-table.dense-xs .v-toolbar__content .v-toolbar__title {
        font-size: 16px;
    }
    .fude-table.dense-xs .v-btn {
        margin: 2px;
    }

    .fude-table .action-btns {
        display: flex;
        justify-content: flex-end;
    }

    .fude-table .action-btns .v-btn {
        margin-right: 0;
    }

    .fude-table .v-toolbar {
        margin-bottom: 0;
    }

    .fude-table .hide-brn {
        visibility: hidden;
    }
</style>