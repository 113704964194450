class Plugin {
    static _installed = [];

    static async init() {
        for (let i = 0; i < Plugin.installed.length; i++) {
            await Plugin.installed[i].init();
        }
    }
    static install(Vue, options = {}) {
        let constructor = this;
        let plugin = new constructor(Vue, options);
        let name = plugin.name;

        let strategies = Vue.config.optionMergeStrategies;
        strategies[name] = strategies.methods;

        let pluginName = `$${name}`;
        Vue.prototype[pluginName] = plugin.prototype();
        Plugin.prototype[pluginName] = plugin;

        let mixin = plugin.mixin();
        if (mixin) {
            Vue.mixin(mixin);
        }
        let methods = plugin.methods();
        if (methods) {
            Object.keys(methods).forEach((key) => {
                Vue.prototype[key] = methods[key];
            });
        }
        let components = plugin.components();
        if (components) {
            for (let i = 0; i < components.length; i++) {
                Vue.component(components[i].name, components[i]);
            }
        }

        Plugin._installed.push(plugin);
    }
    static noop() {};

    static get installed() {
        return Plugin._installed;
    }

    constructor(Vue, options = {}) {
        this._Vue = Vue;
        this._options = options[this.name];
    }

    _v_name() {

    }
    _v_init() {
    }
    _v_mixin(plugin) {
    }
    _v_methods(plugin) {
    }
    _v_components(plugin) {
    }
    _v_prototype(plugin) {
        return plugin;
    }

    init() {
        return this._v_init();
    }
    mixin() {
        return this._v_mixin(this);
    }
    methods() {
        return this._v_methods(this);
    }
    components() {
        return this._v_components(this);
    }
    prototype() {
        return this._v_prototype(this);
    }

    get Vue() { return this._Vue };
    get options() { return this._options };
    get name() {
        return this._v_name();
    }

    get $http() {
        return this.Vue.prototype.$http;
    }
}

export default Plugin;