<template>
    <v-toolbar :extended="$vuetify.breakpoint.sm" :color="color" dense class="fude-search-toolbar" :class="toolbarClass">

        <v-toolbar-title @click="titleClick" :class="{ 'cursor-pointer' : clickableTitle }">
            <template v-if="actions && actions.length">
                <v-tooltip
                        v-for="(action, index) in actions"
                        :key="index"
                        :disabled="!action.tooltip"
                        top
                >
                    <template slot="activator" slot-scope="{ on }">
                        <v-btn
                                :class="{ 'hide-brn' : action.allow && !action.allow(props.item) }"
                                v-on="on"
                                fab
                                dark
                                small
                                :color="action.color"
                                class="xsmall"
                                @click.stop="runAction(action)"
                        >
                            <v-icon>{{ action.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ action.tooltip }}</span>
                </v-tooltip>
            </template>
            {{ title }}
        </v-toolbar-title>

        <v-spacer/>

        <v-text-field
                class="fude-search-toolbar__search"
                v-if="enableSearch"
                v-model="searchText"
                @input="load"
                :placeholder="searchPlaceholder"
                hide-details
                single-line
                prepend-icon="mdi-magnify"
                :color="'white'"
                :append-icon="searchText ? '' : ''"
                @click:append="clearSearchText"></v-text-field>

        <div v-if="rightActions && rightActions.length" style="margin-left: 10px">
            <v-tooltip
                    v-for="(action, index) in rightActions"
                    :key="index"
                    :disabled="!action.tooltip && !action.tooltipHtml"
                    top
            >
                <template slot="activator" slot-scope="{ on }">
                    <v-btn
                            :class="{ 'hide-brn' : action.allow && !action.allow(props.item) }"
                            v-on="on"
                            fab
                            dark
                            small
                            :color="action.color"
                            class="xsmall"
                            @click.stop="runAction(action)"
                    >
                        <v-icon>{{ action.icon }}</v-icon>
                    </v-btn>
                </template>
                <span v-if="action.tooltipHtml" v-html="action.tooltipHtml"></span>
                <span v-else>{{ action.tooltip }}</span>
            </v-tooltip>
        </div>

        <v-btn v-if="enableAutoload" :title="autoloadPlaceholder" :color="autoload ? 'green' : ''" icon @click="autoload=!autoload">
            <v-icon>mdi-cached</v-icon>
        </v-btn>

        <template v-if="enableExtension" slot="extension">
            <v-layout row>
                <v-flex xs12 v-if="enableSearchExtension">
                    <v-text-field
                            v-model="searchText"
                            @input="load"
                            :placeholder="searchPlaceholder"
                            hide-details
                            single-line
                            :color="'white'"
                            prepend-icon="mdi-magnify"
                            :append-icon="searchText ? '' : ''"
                            @click:append="clearSearchText"></v-text-field>
                </v-flex>
                <v-flex xs12>
                    <slot/>
                </v-flex>
            </v-layout>

        </template>
    </v-toolbar>
</template>

<script>
    import Autoload from '../mixins/autoload';

    export default {
        name : 'fude-search-toolbar',

        mixins : [
            Autoload
        ],

        props : {
            actions        : {},
            rightActions   : {},
            color          : {},
            showTitle      : {},
            showSearch     : {},
            showAutoload   : {},
            clickableTitle : {
                type : Boolean
            }
        },

        data() {
            return {
                searchText : '',
                itemsCount : 0
            }
        },

        computed : {
            enableSearch() {
                return this.showSearch && !this.$vuetify.breakpoint.smAndDown;
            },
            enableExtension() {
                return this.enableSearchExtension || this.$slots.default;
            },
            enableSearchExtension() {
                return this.showSearch && this.$vuetify.breakpoint.smAndDown;
            },
            enableAutoload() {
                return this.showAutoload;
            },

            title() {
                return (this.showTitle && this.showTitle.text)
                    ? this.showTitle.text.format(this.itemsCount)
                    : this.$td(this.showTitle, 'fude.search-toolbar.title', this.itemsCount);
            },

            autoloadPlaceholder() {
                return this.$td(this.showAutoload, 'fude.search-toolbar.autoload');
            },

            searchPlaceholder() {
                return this.$td(this.showSearch, 'fude.search-toolbar.search');
            },

            toolbarClass() {
                return (this.actions && this.actions.length)
                    ? 'fude-search-toolbar--actions'
                    : '';
            }
        },

        methods : {
            loadInternal() {
                this.$emit('load', {
                    searchText : this.searchText,
                    itemsCount : (itemsCount) => this.itemsCount = itemsCount
                });
            },

            clearSearchText() {
                this.searchText = '';
                this.load();
            },

            titleClick() {
                if (this.clickableTitle) {
                    this.$emit('titleClick');
                }
            },

            runAction(action) {
                if (action.handle) {
                    let model = {
                        title : this.title
                    };
                    if (action.confirm) {
                        this.$dialog.$confirm(action.confirm(model)).then(() => {
                            action.handle(model);
                        }).catch(() => {});
                    } else {
                        action.handle(model);
                    }
                }
            }
        },

        mounted() {
            this.load();
        }
    }
</script>

<style>
    .fude-search-toolbar.v-toolbar .v-text-field {
        padding-top: 0;
    }
    .fude-search-toolbar .cursor-pointer {
        cursor: pointer;
    }
    .fude-search-toolbar.fude-search-toolbar--actions .v-toolbar__content {
        padding-left: 0;
    }
</style>