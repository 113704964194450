<template>

    <v-card class="fude-data-table" >
        <v-toolbar  :extended="$vuetify.breakpoint.sm">
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer/>

            <v-text-field
                    v-if="enableSearch"
                    v-model="searchText"
                    @input="load"
                    placeholder="Поиск..."
                    hide-details
                    single-line
                    prepend-icon="mdi-magnify"
                    :append-icon="searchText ? 'cancel' : ''"
                    :append-icon-cb="clearSearchText"/>

            <v-btn v-if="enableAutoload" title="Автообновление" :color="autoload ? 'green' : ''" icon @click="autoload=!autoload">
                <v-icon>mdi-cached</v-icon>
            </v-btn>

            <template class="d-block" v-if="enableSearchExtension" slot="extension">
                <v-text-field
                        v-model="searchText"
                        @input="load"
                        placeholder="Поиск..."
                        hide-details
                        single-line
                        prepend-icon="mdi-magnify"
                        :append-icon="searchText ? 'cancel' : ''"
                        :append-icon-cb="clearSearchText"/>
            </template>

        </v-toolbar>

        <v-alert v-if="error" color="error" icon="warning" value="error">
            {{ error }}
        </v-alert>

        <v-data-table
                v-bind:headers="headers"
                v-bind:items="items"
                v-bind:pagination.sync="pagination"
                :total-items="row_count"
                :loading="loading"
                no-data-text="Нет данных"
                hide-actions
                must-sort>

            <template slot="headerCell" slot-scope="props">
                <v-btn v-if="props.header.actions && allowAdd()" fab dark color="green" class="action-btn xsmall" @click.stop="add()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <span v-else>
                  {{ props.header.text }}
                </span>
            </template>

            <template slot="items" slot-scope="props">
                <td v-for="header in headers" v-if="!header.actions">
                    <span v-if="header.html" v-html="header.html(props.item)"></span>
                    <span v-else>{{ props.item[header.field || header.value] }}</span>
                </td>
                <td>
                    <v-speed-dial
                            v-if="hasActions"
                            v-model="props.item.fab"
                            direction="left"
                            open-on-hover
                    >
                        <v-btn slot="activator" color="blue" fab hover v-model="props.item.fab" class="action-btn xsmall">
                            <v-icon>mdi-settings</v-icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn v-if="allowEdit(props.item)" fab dark small color="amber" class="xsmall" @click="edit(props.item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="allowRemove(props.item)" fab dark small color="red" class="xsmall" @click="remove(props.item)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-speed-dial>
                </td>
            </template>

        </v-data-table>
        <div v-if="enablePagination" class="text-xs-center pt-2">
            <v-pagination
                    v-model="pagination.page"
                    :length="pageCount"
                    :total-visible="7"
                    circle
                    :disabled="loading"
            />
        </div>
    </v-card>

</template>

<script>
    import autoload from '../mixins/autoload';

    export default {
        name: "fude-data-table",

        props : ['settings'],

        mixins : [autoload],

        data : function() {
            return {
                headers : [],
                items : [],

                pagination : {
                    rowsPerPage : 18
                },
                row_count : 0,

                searchText : ''

            }
        },

        watch : {
            pagination: {
                handler () {
                    this.load();
                },
                deep: true
            },
            'settings.headers' : {
                handler() {
                    this.headers = this.getHeaders();
                    this.validatePagination(this.headers);
                },
                immediate: true
            },
            'settings.rowsPerPage' : {
                handler() {
                    let rowsPerPage = (this.settings && this.settings.rowsPerPage) || 20;
                    this.pagination.rowsPerPage = rowsPerPage;
                },
                immediate: true
            }
        },

        computed : {
            title() {
                return this.settings && `${this.settings.title} (${this.row_count})`;
            },

            enableSearch() {
                return this.settings && this.settings.search && !this.$vuetify.breakpoint.smAndDown;
            },

            enableSearchExtension() {
                return this.settings && this.settings.search && this.$vuetify.breakpoint.smAndDown;
            },

            pageCount() {
                return this.pagination.rowsPerPage ? Math.ceil(this.row_count / this.pagination.rowsPerPage) : 0
            },

            enableAutoload() {
                let result = this.settings && this.settings.autoload;
                if (result) {
                    this.autoload = true;
                }
                return result;
            },

            enablePagination() {
                if (this.settings && this.settings.pagination == false) {
                    return false;
                }
                return true;
            },

            hasActions() {
                return this.settings && !!this.settings.actions;
            },

            searchUrl() {
                if (this.settings && this.settings.rest) {
                    return this.settings.rest + '/search/';
                }
                return null;
            },

            addUrl() {
                return this.settings.rest;
            },

            updateUrl() {
                return this.settings.rest;
            }
        },

        methods : {

            allowAction(item, action) {
                return this.settings.actions && this.settings.actions[action] && (
                    !this.settings.actions[action].allow ||
                    this.settings.actions[action].allow(item));
            },

            allowAdd() {
                return this.allowAction(null, 'add');
            },

            allowEdit(item) {
                return this.allowAction(item, 'edit');
            },

            allowRemove(item) {
                return this.allowAction(item, 'remove');
            },

            clearSearchText() {
                this.searchText = '';
            },

            setError(error) {
                if (!error) {
                    return this.error = null;
                }
                if (error.response && error.response.data) {
                    this.error = error.response.data;
                } else {
                    this.error = error.message || error;
                }

                console.log(error);
            },

            getHeaders() {
                let result = [];

                if (this.settings && this.settings.headers) {
                    this.settings.headers.forEach((header) => {
                        result.push({
                            text: header.text,
                            field: header.field,
                            html: header.html,
                            value: header.value,
                            width: header.width,
                            class: header.class,
                            sortable: header.sortable == null ? true : header.sortable,
                            align: 'left'
                        });
                        if (header.default) {
                            this.pagination.sortBy = header.value;
                            this.pagination.descending = header.default == 'desc';
                        }
                    });
                    result.push({
                        actions: true,
                        align: 'right',
                        width: 50,
                        sortable: false,
                        value: '_actions_'
                    });
                }

                return result;
            },

            loadInternal () {

                let searchUrl = this.searchUrl;
                if (!searchUrl) {
                    return;
                }

                let query = {
                    page  : this.pagination.page - 1,
                    count : this.pagination.rowsPerPage,
                    sort  : this.pagination.sortBy,
                    desc  : this.pagination.descending,
                    text  : this.searchText
                };

                let query_str = encodeURIComponent(JSON.stringify(query));

                this.$http.get(`${searchUrl}${query_str}`).then((res) => {

                    res.data.forEach((d) => d.fab = false);

                    if (this.settings.onLoad){
                        this.settings.onLoad(res.data);
                    }

                    this.items = res.data;
                    this.row_count = this.items.length ? parseInt(this.items[0].row_count) : 0;

                }).catch((error) => {
                    this.setError(error);
                });
            },

            validatePagination(headers) {
                if (!headers.filter((header) => header.sortable && header.value == this.pagination.sortBy).length) {
                    let sorts = headers.filter((header) => header.sortable);
                    if (sorts.length) {
                        this.pagination.sortBy = sorts[0].value;
                        this.pagination.descending = false;
                    } else {
                        this.pagination.sortBy = '';
                    }
                }
            },

            getModel(item) {
                if (this.settings.actions.model) {
                    return Promise.resolve(this.settings.actions.model(item));
                }
                return Promise.resolve(item);
            },

            getDto(item) {
                if (this.settings.actions.dto) {
                    return Promise.resolve(this.settings.actions.dto(item));
                }
                return Promise.resolve(item);
            },


            add() {
                this.getModel({}).then((model) => {
                    this.$dialog.$show({
                        title : this.settings.actions.add.title,
                        model,
                        component : this.settings.actions.dialog,
                        actions : [{
                            result : 'OK',
                            name : 'OK',
                            handle : () => {
                                return this.getDto(model.item).then((dto) => {
                                    return this.$http.post(this.addUrl, dto).then(() => {
                                        this.load();
                                    });
                                });
                            }
                        },{
                            result : 'CANCEL',
                            name : 'Отмена'
                        }]
                    });
                }).catch((error) => {
                    console.log(error);
                });
            },

            edit(item) {
                this.getModel(item).then((model) => {
                    this.$dialog.$show({
                        title : this.settings.actions.edit.title,
                        model,
                        component : this.settings.actions.dialog,
                        actions : [{
                            result : 'OK',
                            name : 'OK',
                            handle : () => {
                                return this.getDto(model.item).then((dto) => {
                                    return this.$http.put(`${this.updateUrl}/${model.item.id}`, dto).then(() => {
                                        this.load();
                                    });
                                });
                            }
                        },{
                            result : 'CANCEL',
                            name : 'Отмена'
                        }]
                    });
                }).catch((error) => {
                    console.log(error);
                });
            },

            remove(item) {
                this.$dialog.$confirm(this.settings.actions.remove.title(item)).then(() => {
                    this.$http.delete(`${this.settings.rest}/${item.id}`).then(() => {
                        this.load();
                    }).catch((error) => {
                        this.setError(error);
                    });
                }).catch(() => {

                });
            }
        },

        mounted() {
            this.load();
        }

    }
</script>

<style>
    .fude-data-table table.table thead td:not(:nth-child(1)),
    .fude-data-table table.table tbody td:not(:nth-child(1)),
    .fude-data-table table.table thead th:not(:nth-child(1)),
    .fude-data-table table.table tbody th:not(:nth-child(1)),
    .fude-data-table table.table thead td:first-child,
    .fude-data-table table.table tbody td:first-child,
    .fude-data-table table.table thead th:first-child,
    .fude-data-table table.table tbody th:first-child {
        padding: 0 10px;
    }

    .fude-data-table .action-btn {
        margin: 2px;
    }

    /*.xsmall.v-btn {
        width: 34px;
        height: 34px;
    }*/
    .btn--bottom.btn--absolute.xsmall {
        bottom: -17px;
    }

    .fude-data-table table.table tbody td,
    .fude-data-table table.table tbody th {
        height: 32px !important;
    }
</style>