<template>
    <fude-input-field :label="label" class="f-rich-text">
        <div>
            <editor-menu-bar :editor="editor" class="f-rich-text__menu">
                <div slot-scope="{commands, isActive}">
                    <v-btn @click="commands.italic" :input-value="isActive.italic()" small icon>
                        <v-icon>mdi-format-italic</v-icon>
                    </v-btn>
                    <v-btn @click="commands.bold" :input-value="isActive.bold()" small icon >
                        <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                    <v-btn @click="commands.strike" :input-value="isActive.strike()" small icon>
                        <v-icon>mdi-format-strikethrough</v-icon>
                    </v-btn>
                    <v-btn  @click="commands.underline" :input-value="isActive.underline()" small icon>
                        <v-icon>mdi-format-underline</v-icon>
                    </v-btn>
                    <v-btn  @click="commands.code" :input-value="isActive.code()" small icon>
                        <v-icon>mdi-code-tags</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.heading({ level: 1 })" :input-value="isActive.heading({ level: 1 })" small icon>
                        <v-icon>mdi-format-header-1</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.heading({ level: 2 })" :input-value="isActive.heading({ level: 2 })" small icon>
                        <v-icon>mdi-format-header-2</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.heading({ level: 3 })" :input-value="isActive.heading({ level: 3 })" small icon>
                        <v-icon>mdi-format-header-3</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.bullet_list" :input-value="isActive.bullet_list()" small icon>
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.ordered_list" :input-value="isActive.ordered_list()" small icon>
                        <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                    <!--<v-btn flat @click="commands.blockquote" :input-value="isActive.blockquote()" small icon>
                        <v-icon>mdi-format-quote-close</v-icon>
                    </v-btn>-->
                    <v-btn flat @click="commands.undo" small icon>
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    <v-btn flat @click="commands.redo"  small icon>
                        <v-icon>mdi-redo</v-icon>
                    </v-btn>

                </div>
            </editor-menu-bar>
            <editor-content :editor="editor" class="f-rich-text__content" />
        </div>
    </fude-input-field>


</template>

<script>
    import {
        Editor,
        EditorContent,
        EditorMenuBar
    } from 'tiptap';
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
        Image,
        Placeholder
    } from 'tiptap-extensions';
    import { debounce } from 'lodash';

    export default {

        components: {
            EditorContent,
            EditorMenuBar,
        },
        name: 'f-rich-editor',

        props: {
            value       : { },
            label       : { },
            placeholder : { }
        },

        data() {
            return {
                editor : new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Link(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new Image(),
                        new Placeholder({
                            emptyClass: 'is-empty',
                            emptyNodeText: this.placeholder,
                        }),
                    ],
                    content: this.value || '',
                    onUpdate: debounce(({ getHTML }) => {
                        this.updating = true;
                        let html = getHTML();
                        if (html === '<p></p>') {
                            html = null;
                        }
                        this.$emit('input', html);
                        this.$nextTick(()=> {
                            this.updating = false;
                        })
                    }, 500)
                }),
                updating : false
            }
        },

        watch: {
            value() {
                if (!this.updating) {
                    this.editor.setContent(this.value || '');
                }
            }
        },

        beforeDestroy() {
            this.editor.destroy();
        }
    }
</script>

<style>

    .f-rich-text .ProseMirror{
        min-height: 100px;
        outline-color: transparent!important;
        word-break: break-all;
    }

    .f-rich-text__menu {
        margin-top: 4px;
        /*border-top: solid 1px;
        border-bottom: solid 1px;*/
        /*border-radius: 5px 5px 0 0;*/
    }

    .f-rich-text__content {
        /*border: solid 1px ;*/
        /*border-radius: 0 0 5px 5px;*/
    }

    .f-rich-text .v-icon{
        font-size: 18px;
    }
    .f-rich-text .v-btn--icon{
        margin: 2px;
    }

    .f-rich-text p.is-empty:first-child::before {
        content: attr(data-empty-text);
        float: left;
        color: #aaa;
        pointer-events: none;
        height: 0;
    }
</style>