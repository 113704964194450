<template>
    <v-dialog
            v-model="show"
            :fullscreen="fullScreen"
            :content-class="contentClass"
            persistent
            @keydown.esc.stop="dialog.active && setResult()"
            ref="dialog"
            scrollable
    >
        <v-card class="fude-dialog-card" >
            <v-card-title v-if="dialog.title" class="teal">
                <h3>{{ dialog.title }}</h3>
                <v-spacer/>
                <div v-if="actionsTop">
                    <v-btn v-for="action in dialog.actions" :key="action.result" @click="setResult(action)" :disabled="loading" :color="action.color">
                        <v-progress-circular v-if="action.loading" indeterminate></v-progress-circular>
                        <span v-else>{{ action.name }}</span>
                    </v-btn>
                </div>
                <div else-if="topActions && topActions.length">
                    <v-btn v-for="action in topActions" :key="action.result" @click="setResult(action)" :disabled="loading" :color="action.color">
                        <v-progress-circular v-if="action.loading" indeterminate></v-progress-circular>
                        <span v-else>{{ action.name }}</span>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text v-if="dialog.component">
                <v-container grid-list-md class="fude-dialog-container" :class="containerClass">
                    <component :is="dialog.component" :model="dialog.model" @set-result="setResult" ref="component"/>
                </v-container>
            </v-card-text>
            <v-card-actions v-if="errorMessage" class="pb-0">
                <v-alert color="error" icon="mdi-alert-circle" value="true" v-html="errorMessage"/>
            </v-card-actions>
            <v-card-actions v-if="dialog.actions && dialog.actions.length && !actionsTop">
                <v-btn v-for="action in leftActions" :key="action.result" @click="setResult(action)" :disabled="loading" :color="action.color">
                    <v-progress-circular v-if="action.loading" indeterminate></v-progress-circular>
                    <span v-else>{{ action.name }}</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-for="action in rightActions" :key="action.result" @click="setResult(action)" :disabled="loading" :color="action.color">
                    <v-progress-circular v-if="action.loading" indeterminate></v-progress-circular>
                    <span v-else>{{ action.name }}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "fude-dialog",

        props : {
            dialog : {
                default : {},
                type : Object
            }
        },

        data() {
            return {
                show    : false,
                loading : false
            }
        },

        watch : {
            show() {
                if (this.show) {
                    this.dialog && this.dialog.onShow && this.dialog.onShow();
                } else {
                    this.dialog && this.dialog.onHide && this.dialog.onHide();
                }
            }
        },

        computed : {
            leftActions() {
                return this.dialog.actions && this.dialog.actions.filter((a) => a.left && !a.top);
            },
            rightActions() {
                return this.dialog.actions && this.dialog.actions.filter((a) => !a.left && !a.top);
            },
            topActions() {
                return this.dialog.actions && this.dialog.actions.filter((a) => !!a.top);
            },

            contentClass() {
                return [
                    'fude-dialog',
                    this.dialogClass,
                    this.sizeClass,
                    (this.dialog && this.dialog.actionsTop) ? 'fude-dialog--actions-top' : ''
                ].join(' ');
            },
            sizeClass() {
                if (this.dialog.autoWidth === false || this.fullScreen) {
                    return '';
                }
                return `auto-width ${this.$vuetify.breakpoint.name}`;
            },
            dialogClass() {
                return `fude-dialog-${this.dialog.id}`;
            },
            fullScreen() {
                if (this.dialog.fullScreen) {
                    if (typeof this.dialog.fullScreen === 'function') {
                        return this.dialog.fullScreen(this.dialog.model);
                    }
                    return true;
                }
                return this.$vuetify.breakpoint.xsOnly;
            },
            actionsTop() {
                return this.dialog && this.dialog.actionsTop && !this.$vuetify.breakpoint.xsOnly;
            },
            containerClass() {
                return this.dialog && this.dialog.wideContainer ? 'fude-dialog-container--wide' : '';
            }
        },

        methods : {
            setResult(action) {
                if (this.loading) {
                    return;
                }
                if (typeof action === 'string') {
                    action = this.dialog.actions.find((a) => a.result === action);
                }
                if (!action) {
                    action = (this.dialog.actions && this.dialog.actions.find((a) => a.result === 'CANCEL') || {});
                }
                this.$setError();
                if (action.handle) {
                    if (action.confirm) {
                        this.$dialog.$confirm(action.confirm()).then(() => {
                            this.runAction(action);
                        }).catch(() => {});
                    } else {
                        this.runAction(action);
                    }
                } else {
                    this.close();
                }
            },

            async runAction(action) {
                try {
                    action.loading = this.loading = true;
                    let valid = true;
                    if (this.$refs.component.validate) {
                        valid = await this.$refs.component.validate();
                    }
                    if (valid) {
                        await Promise.resolve(action.handle());
                        if (action.close !== false) {
                            this.close();
                        }
                    }
                } catch (error) {
                    this.$setError(error);
                } finally {
                    action.loading = this.loading = false;
                }
            },

            close() {
                if (this._overlay.el) {
                    this._overlay.el.removeEventListener('click', this._overlay.click);
                }
                this.show = false;
                this.$emit('startClose');
                setTimeout(() => this.$emit('close'), 500);
            }
        },

        mounted() {
            this.show = true;
            this.$nextTick(() => {
                this._overlay = {
                    el : document.querySelector('.v-overlay:not([data-id])'),
                    click : () => this.setResult()
                };
                if (this._overlay.el) {
                    this._overlay.el.setAttribute('data-id', this.dialog.id);
                    this._overlay.el.addEventListener('click', this._overlay.click);
                }
            });
        }
    }
</script>

<style>
    .fude-dialog-card .v-card__title,
    .fude-dialog-card .v-card__text {
        padding: 8px 16px !important;
    }

    .fude-dialog .fude-dialog-container,
    .fude-dialog .fude-dialog-container > .layout{
        padding: 0;
    }

    .fude-dialog .fude-dialog-container.fude-dialog-container--wide {
        max-width: 100%!important;
    }

    .fude-dialog .v-card__text {
        padding: 16px !important;
        padding-bottom: 0!important;
    }
    .fude-dialog .v-card__actions {
        padding: 16px !important;
    }

    .fude-dialog .fude-dialog-card.v-card.theme--dark {
        background-color: #303030;
    }

    .fude-dialog.auto-width {
        width: auto !important;
    }
    .fude-dialog.auto-width.xs {
        min-width: 220px;
    }
    .fude-dialog.auto-width.sm {
        min-width: 400px;
    }
    .fude-dialog.auto-width.md {
        min-width: 500px;
    }
    .fude-dialog.auto-width.lg {
        min-width: 500px;
    }
    .fude-dialog.auto-width.xl {
        min-width: 500px;
    }
    .fude-dialog.fude-dialog--actions-top .v-card__title {
        padding: 10px 8px 10px 16px;
    }
    .fude-dialog .v-card__actions .v-alert {
        width: 100%;
    }

    .fude-dialog.v-dialog--scrollable > .v-card > .v-card__title,
    .fude-dialog.v-dialog--scrollable > .v-card > .v-card__actions {
        flex-grow: 0;
    }

    @media only screen and (min-width: 1904px) {
        .fude-dialog-container {
            max-width: 1185px;
        }
    }

</style>